<template>
  <div>
    <div class="mb-5 text-left">
      <h1 class="text-2xl text-gray-800 col-span-2">
        {{ title }}
      </h1>
      <span class="leading-1 text-gray-500">{{ subtitle }}</span>
    </div>

    <div v-if="data.length">
      <a-list
        :size="sizeList"
        class="demo-loadmore-list"
        :loading="loading"
        item-layout="horizontal"
        :data-source="data"
      >
        <div
          v-if="loadMore"
          class="my-3"
          slot="loadMore"
          :style="{
            textAlign: 'center',
            marginTop: '12px',
            height: '32px',
            lineHeight: '32px',
          }"
        >
          <a-spin v-if="loadingMore" />
          <a
            class="text-lg text-blue-700 font-bold"
            v-else-if="notificationBell"
            @click="onLoadMore"
          >
            Ver más</a
          >
          <a-button v-else @click="onLoadMore" type="primary" shape="round" ghost class="mt-3" > Ver más</a-button>
        </div>

        <a-list-item
          slot="renderItem"
          slot-scope="item"
          class="text-left px-2 rounded-xl relative flex hover:bg-gray-50"
          :class="item.viewed ? 'bg-gray-50 px-2' : ''"
        >
          <div slot="actions" class="grid  relative " @click="onViewed(item.id)">
          <span  v-if="sizeList === 'default'" class=" relative text-xs bottom-2 ">{{new Date(item.date_alert).toLocaleDateString('es', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }}</span>
            <a
              class="text-blue-700 mt-1"
              v-if="!item.viewed && sizeList === 'default'"
              >Marcar como leída</a
            >
            <a v-if="!item.viewed && sizeList === 'small'"
              ><a-icon type="check" /><a-icon type="check"
            /></a>

          </div>
          <a-list-item-meta :description="item.description.replace(/\[|\]/g, '')" > 
            <a v-if="item.complaint_code" slot="title" @click="complaintDetails(item.complaint_code)">{{ item.title || capitalize }}</a> 
            <a v-else slot="title" class="no-pointer">{{
              item.title || capitalize
            }} </a> 
            <a-icon
            class=" my-auto"
              slot="avatar"
              type="bell"
              :style="
                item.viewed
                  ? { fontSize: '24px', color: 'gray' }
                  : { fontSize: '24px', color: item.alert_type_color }
              "
            />
          </a-list-item-meta>
          
         
        </a-list-item>
      </a-list>
    </div>
    <div v-else-if="loading" class="w-full mx-auto mt-7 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <div v-else class="relative top-16">
      <span class=" text-2xl text-gray-400"
        >No hay notificaciones</span
      >
    </div>
  </div>
</template>
<script>
import BaseRangeDate from "../../UI/BaseInput/BaseRangeDate.vue";
import moment from "moment";
export default {
  props: {
    sizeList: {
      type: String,
      default: "default",
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    data: {
      type: [],
      required: true,
    },
    alert_title: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadMore: {
      type: Boolean,
      default: true,
    },
    notificationBell: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingMore: false,
    };
  },
  computed:{
    alertDate:function(){

    }
  },
  methods: {
    complaintDetails(complaint_code) {
      const url = this.$router.resolve({
        name: "ComplaintDetails",
        query: { complaint_id: complaint_code },
      }).href;
      window.open(url, '_blank');
    },
    onLoadMore() {
      this.loadingMore = true;
      this.$emit("loadMore");
      setTimeout(() => {
        this.loadingMore = false;
      }, 1000);
    },
    onViewed(item) {
      this.$emit("viewed", item);
    },
  },
  components: { BaseRangeDate },
};
</script>
<style scoped>
.demo-loadmore-list {
  min-height: 350px;
}
.ant-list-item:hover {
  background-color: rgb(250, 250, 250);
  border-radius: 7px;
}
.ant-list-item-meta-title a:hover {
  color: inherit;
}

.ant-list-item-meta-avatar{
  margin: auto 16px;
  margin-left:3px;
}

.no-pointer {
  cursor: default;
}
</style>
