import { render, staticRenderFns } from "./AlertListBaseEv.vue?vue&type=template&id=e282c4f2&scoped=true"
import script from "./AlertListBaseEv.vue?vue&type=script&lang=js"
export * from "./AlertListBaseEv.vue?vue&type=script&lang=js"
import style0 from "./AlertListBaseEv.vue?vue&type=style&index=0&id=e282c4f2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e282c4f2",
  null
  
)

export default component.exports