var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-5 text-left"},[_c('h1',{staticClass:"text-2xl text-gray-800 col-span-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('span',{staticClass:"leading-1 text-gray-500"},[_vm._v(_vm._s(_vm.subtitle))])]),(_vm.data.length)?_c('div',[_c('a-list',{staticClass:"demo-loadmore-list",attrs:{"size":_vm.sizeList,"loading":_vm.loading,"item-layout":"horizontal","data-source":_vm.data},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{staticClass:"text-left px-2 rounded-xl relative flex hover:bg-gray-50",class:item.viewed ? 'bg-gray-50 px-2' : ''},[_c('div',{staticClass:"grid relative",attrs:{"slot":"actions"},on:{"click":function($event){return _vm.onViewed(item.id)}},slot:"actions"},[(_vm.sizeList === 'default')?_c('span',{staticClass:"relative text-xs bottom-2"},[_vm._v(_vm._s(new Date(item.date_alert).toLocaleDateString('es', { weekday:"long", year:"numeric", month:"short", day:"numeric"})))]):_vm._e(),(!item.viewed && _vm.sizeList === 'default')?_c('a',{staticClass:"text-blue-700 mt-1"},[_vm._v("Marcar como leída")]):_vm._e(),(!item.viewed && _vm.sizeList === 'small')?_c('a',[_c('a-icon',{attrs:{"type":"check"}}),_c('a-icon',{attrs:{"type":"check"}})],1):_vm._e()]),_c('a-list-item-meta',{attrs:{"description":item.description.replace(/\[|\]/g, '')}},[(item.complaint_code)?_c('a',{attrs:{"slot":"title"},on:{"click":function($event){return _vm.complaintDetails(item.complaint_code)}},slot:"title"},[_vm._v(_vm._s(item.title || _vm.capitalize))]):_c('a',{staticClass:"no-pointer",attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.title || _vm.capitalize)+" ")]),_c('a-icon',{staticClass:"my-auto",style:(item.viewed
                ? { fontSize: '24px', color: 'gray' }
                : { fontSize: '24px', color: item.alert_type_color }),attrs:{"slot":"avatar","type":"bell"},slot:"avatar"})],1)],1)}}],null,false,2973665781)},[(_vm.loadMore)?_c('div',{staticClass:"my-3",style:({
          textAlign: 'center',
          marginTop: '12px',
          height: '32px',
          lineHeight: '32px',
        }),attrs:{"slot":"loadMore"},slot:"loadMore"},[(_vm.loadingMore)?_c('a-spin'):(_vm.notificationBell)?_c('a',{staticClass:"text-lg text-blue-700 font-bold",on:{"click":_vm.onLoadMore}},[_vm._v(" Ver más")]):_c('a-button',{staticClass:"mt-3",attrs:{"type":"primary","shape":"round","ghost":""},on:{"click":_vm.onLoadMore}},[_vm._v(" Ver más")])],1):_vm._e()])],1):(_vm.loading)?_c('div',{staticClass:"w-full mx-auto mt-7 left-0"},[_c('a-spin',[_c('a-icon',{staticStyle:{"font-size":"64px"},attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"})],1)],1):_c('div',{staticClass:"relative top-16"},[_c('span',{staticClass:"text-2xl text-gray-400"},[_vm._v("No hay notificaciones")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }