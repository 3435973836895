<template>
    <NotificationsEv />
  </template>
  
  <script>
  import NotificationsEv from "@/components/Dashboard/Alerts/NotificationsEv.vue";
  
  export default {
    components: { NotificationsEv },
  };
  </script>
  